import axios from '@/services/ionsyntaxchecker-axios'

const medeurApi = {
  get: (id) => axios.get(`/api/medeur/${id}`),
  uploadFiles(ediFile, zipFile, uploadCB) {
    const data = new FormData();
    data.append("ediFile", ediFile);
    data.append("zipFile", zipFile);

    return axios.post('/api/medeur/files', data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (event) => {
        if (uploadCB) {
          uploadCB(Math.round((event.loaded * 100) / event.total))
        }
      }
    });
  }
}

const userApi = {
  sendJwtLink: (email) => axios.get(`/api/user/sendLink/${email}`)
}

export default {
  medeurApi,
  userApi
}
