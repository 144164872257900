<template>
  <v-app>
    <v-app-bar app
               clipped-left
               dark
               color="accent"
               class="d-print-none"
    >

<!--      <v-app-bar-nav-icon class="d-flex d-lg-none" @click="drawer = !drawer"></v-app-bar-nav-icon>-->

      <div class="d-flex justify-start align-center">
        <a href="https://leapstation.eu/" class="pl-2 pr-2" style="max-width: 130px; max-height: 60px;">
          <v-img
            dark
            src="/assets/img/Logo_Leapstation.png"
            height="100%"
            max-height="60"
            contain></v-img>
        </a>
        <a href="https://inschrijvingopnaam.nl/" class="pl-2 pr-2" style="max-width: 130px; max-height: 60px;">
          <v-img
            src="/assets/img/Logo_St_ION.svg"
            height="100%"
            max-height="60"
            contain></v-img>
        </a>
        <a href="https://www.lhv.nl/" class="pl-2 pr-2" style="max-width: 130px; max-height: 60px;">
          <v-img
            src="/assets/img/Logo_LHV.svg"
            height="100%"
            max-height="60"
            contain></v-img>
        </a>
        <a href="https://www.nedxis.nl/" class="pl-2 pr-2" style="max-width: 130px; max-height: 60px;">
          <v-img
            src="/assets/img/Logo_NedXis.png"
            height="100%"
            max-height="60"
            contain></v-img>
        </a>
      </div>

      <v-spacer></v-spacer>
      <div class="d-flex justify-end align-center">
        <span>{{email}}</span>
        <v-divider vertical
                   class="mx-3 white">
        </v-divider>
        <v-btn v-if="allowRestart" @click.prevent="restart">Restart</v-btn>
      </div>

    </v-app-bar>
    <v-main :style="$vuetify.theme.dark ? '' : 'background-color: #F4F6F9;'">
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
import i18n from "@/services/i18n";
export default {
  i18n: i18n,
  name: "ionsyntaxchecker-layout",
  props: ['state', 'title', 'email', 'restartallowed'],

  data() {
    return {
      allowRestart: new URL(window.location).toString().includes("filechecker")
    }
  },

  mounted() {
    if (this.state.language){
      this.$i18n.locale = this.state.language
    } else {
      this.$i18n.locale = 'nl'
    }
  },

  methods:{
    restart() {
      window.location = "/fileupload"
    }
  }
};
</script>

<style lang="scss">

/*.v-application {
  font-family:  'Fira Sans', sans-serif !important;
}*/

p {
  font-size: 1.2rem !important;
  line-height: 1.8rem !important;
  margin-bottom: 0 !important;
}

h1, h2 {
  margin-bottom: 12px !important;
}

.v-application {
  font-family:  'Poppins', sans-serif !important;
}
/*.v-main {
  background-color: #F4F6F9;
}*/

/*.v-list-item__title {
  color: #0D47A1;
}*/

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 35px !important;
}

.editor-content-rendered p{
  margin-bottom: 1rem !important;
}

@media print {
  .v-main {
    background-color: #FFFFFF!important;
    padding: 0!important;
  }

  .theme--dark.v-divider {
    border-color: rgba(0,0,0,.12)!important;
  }

  .theme--dark.v-application {
    color: #000000!important;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel,
  .theme--dark.v-list-item,
  .theme--dark.v-list-item .v-list-item__content,
  .theme--dark.v-list-item .v-list-item__title {
    background-color: #FFFFFF!important;
    color: #000000!important;
  }

  .v-expansion-panel-header__icon {
    display: none!important;
  }

  a[href^=http]:after {
    content:" <" attr(href) "> ";
  }

  @page {
    margin: 2cm;
  }
}
</style>
