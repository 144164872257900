<template>
  <v-card v-if="structure">
    <v-card>
      <v-card-title>Summary</v-card-title>
      <v-card-text>
        <p>Geverifieerd door: {{email}}</p>
        <p>EDI Bestandsnaam: {{ediName}}</p>
        <p>ZIP Bestandsnaam: <span v-if="zipName !== 'null'">{{zipName}}</span></p>
        <p>Aantal fouten: {{structure.amountOfErrors}}</p>
        <p>Datum: {{ new Date() | moment("DD/MM/YYYY") }}</p>
        <br>
        <p>Ongerefereerde bestanden:</p>
        <ul>
          <li v-for="(unreferencedFile, ndx) in structure.unreferencedFiles" :key="ndx">{{ unreferencedFile }}</li>
        </ul>
        <br>
        <p>Globale errors</p>
        <ul>
          <li v-for="(error, ndx) in structure.errors" :key="ndx">{{ error }}</li>
        </ul>
      </v-card-text>
    </v-card>
    <v-container fluid>
      <v-card tile class="ma-2" v-for="(sg,ndx) in structure.lineSegmentGroups" :key="ndx">
        <v-card-text>
          <v-row>
            <v-col cols="1">
              <v-row>
                {{sg.type}}
              </v-row>
            </v-col>
            <v-col cols="11">
              <div class="mb-4 error--text">
                <span v-for="(error, errorNdx) in sg.errors" :key="errorNdx">
                  {{error}}
                </span>
              </div>
              <v-row v-bind:style="ndxLines !== sg.lines.length - 1? 'border-bottom: 1px solid; border-color: gray' : ''" v-for="(line,ndxLines) in sg.lines" :key="ndxLines">
                <v-col cols="5">
                  {{ line.line }}
                </v-col>
                <v-col class="error--text" cols="5">
                  <span v-if="line.errors.length">{{line.errors.join(", ")}}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import api from "@/api/api";

export default {
  name: "ion-file-checker",
  props: ['id', 'email'],
  data() {
    return {
      headers: [
        {
          text: 'type',
          value: 'type',
        },
        {
          text: 'type',
          value: 'type',
        },
        {
          text: 'remarks',
          value: 'remarks',
        },
        ],
        structure: null,
      medeurErrors: [],
      ediName: '',
      zipName: ''
    }
  },
  mounted() {
    const that = this
    api.medeurApi.get(that.id)
      .then(res => {
        that.structure = res.data
        that.ediName = res.data.sessionData.ediFile
        that.zipName = res.data.sessionData.zipFile
      })
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
