<template>
  <v-container>
    <div class="d-flex justify-center align-center mt-12">
      <v-fade-transition>
        <v-card
          tile
          class="col-12 col-md-6"
        >
          <v-card-title class="justify-center">
            Bestand Upload
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-file-input
              show-size
              counter
              accept=".edi"
              v-model="ediFile"
              label="Kies .edi bestand"
            ></v-file-input>
            <v-file-input
              show-size
              counter
              accept=".zip"
              v-model="zipFile"
              label="Kies .zip bestand"
            ></v-file-input>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              tile
              color="primary"
              @click.prevent="uploadFiles()"
            >
              Upload bestanden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </div>
  </v-container>
</template>

<script>
import api from "@/api/api";

export default {
  name: "ion-file-upload",
  data() {
    return {
      ediFile: null,
      zipFile: null
    }
  },
  methods: {
    uploadFiles() {
      const that = this

      api.medeurApi.uploadFiles(that.ediFile, that.zipFile)
        .then(res => {
          window.location = `/filechecker/${res.data.id}`
        })
    }
  },
}
</script>

<style scoped>

</style>
