<template>
  <div class="d-flex justify-center align-center mt-12">
    <v-fade-transition>
      <v-card
        tile
        class="col-12 col-md-6"
      >
        <v-card-title class="justify-center">
          Verkrijg login link
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="email"
            label="Email"
          ></v-text-field>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            tile
            color="primary"
            :disabled="!email"
            @click.prevent="sendCode()"
          >
            Verzend login link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-fade-transition>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  name: "ion-login",
  data() {
    return {
      email: null
    }
  },
  methods: {
    sendCode(){
      const that = this

      api.userApi.sendJwtLink(that.email)
        .then(() => {
          that.$toast.open({
            type: 'success',
            message:  `Een email met de login link is verzonden naar ${that.email} indien de gebruiker de applicatie mag gebruiken.`,
            position: 'bottom',
            duration: 5000,
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
