import { VueMaskDirective } from 'v-mask'

import IonFileChecker from "@/components/IonFileChecker";
import IonFileUpload from "@/components/IonFileUpload";
import IonLogin from "@/components/IonLogin";

const MediligoPortalPlugin = {
  install(Vue) {
    Vue.directive('mask', VueMaskDirective);

    Vue.component(IonFileChecker.name, IonFileChecker)
    Vue.component(IonFileUpload.name, IonFileUpload)
    Vue.component(IonLogin.name, IonLogin)
  }
}

export { MediligoPortalPlugin }
