import Vue from 'vue'

import VueMasonry from 'vue-masonry-css'

import { LayoutsPlugin } from './layouts/layouts'
import { MediligoPortalPlugin } from './components/components'
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';
import AsyncComputed from "vue-async-computed";

const moment = require('moment')

Vue.use(MediligoPortalPlugin)
Vue.use(LayoutsPlugin)

Vue.use(VueMasonry);
Vue.use(AsyncComputed);
Vue.use(VueToast);
Vue.use(require('vue-moment'), {
  moment
});

Vue.config.productionTip = false


if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
}
